import React from "react"

import { FaAtom, FaChalkboard } from "react-icons/fa"

export const solutions = [
	{
		title: "Electronic | IOT Solution",
		description:
			"To respond the demand of sensor devices for agricultural, environmental and ICT sector, our company are here to provide the development on hardware, software, and mobile application upon the requirement of each project.",
		icon: <FaAtom size={32} />,
		path: "/solutions/electronic-and-iot",
	},
	{
		title: "Automation | Robotic Solution",
		description:
			"We provide the solution for Industrial 3.0 and 4.0 to all types of manufacture​r in Cambodia that required robotics such as robot arm, conveyor system, smart production process to improve the quality and speed of production.",
		icon: <FaChalkboard size={32} />,
		path: "/solutions/automation-and-robotic",
	},
]

export const electronic_iot = solutions[0]
export const automation_robotic = solutions[1]
