import React from "react"
import styled from "@emotion/styled"

import Inner from "../../../components/Inner"
import TimelineItem from "../../../components/TimelineItem"

import * as Color from "../../../utils/color"

const List = styled.div`
	display: grid;
	grid-row-gap: 64px;
`

const Timeline = styled.section``

const Section = ({ list = [] }) => {
	return (
		<Timeline>
			<Inner>
				<List>
					{list.map((item, index) => {
						return (
							<TimelineItem
								counter={index + 1}
								title={item.title}
								detail={item.detail}
								year={item.year}
								imageUrl={item.thumbnail.publicURL}
							/>
						)
					})}
				</List>
			</Inner>
		</Timeline>
	)
}

export default Section
