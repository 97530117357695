import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import * as Color from "../../utils/color"
import { mobile, tablet } from "../../utils/breakpoint"

const Detail = styled.div`
	${tw`text-base`}
	color: ${Color.primary_800};
`

const Header = styled.div`
	${tw`text-4xl leading-tight`}
	font-weight: bold;
	color: ${Color.primary};
`

const Year = styled.div`
	${tw`text-xs tracking-wide`}
	font-weight: bold;
	color: ${Color.primary};
`

const Image = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 8px;

	object-fit: cover;
`

const Thumbnail = styled.div`
	@media ${mobile} {
		${tw`mb-3`}
	}
`

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Counter = styled.div`
	${tw`text-2xl`}
	font-weight: bold;

	width: 60px;
	height: 60px;
	border-radius: 50%;

	color: ${Color.primary};
	background-color: ${Color.primary_100};
	display: flex;
	justify-content: center;
	align-items: center;

	@media ${mobile} {
		${tw`mb-4`}
	}
`

const Left = styled.div`
	display: flex;
	align-items: center;
`

const Right = styled.div`
	display: inherit;
	grid-template-areas: "thumbnail info";
	grid-template-columns: 450px auto;
	grid-template-rows: 340px;
	grid-column-gap: 28px;

	${Thumbnail} {
		grid-area: thumbnail;
	}

	${Info} {
		grid-area: info;
	}

	@media ${mobile} {
		grid-template-areas: "thumbnail" "info";
		grid-template-columns: 1fr;
	}

	@media ${tablet} {
		grid-template-columns: 1fr 1fr;
	}
`

const Grid = styled.div`
	display: grid;
	grid-template-areas: "left right";
	grid-template-columns: 60px auto;
	grid-column-gap: 32px;

	${Left} {
		grid-area: left;
	}

	${Right} {
		grid-area: right;
	}

	@media ${mobile} {
		grid-template-areas: "left" "right";
		grid-template-columns: unset;
	}
`

const TimelineItem = styled.div``

const Wrapper = ({ counter, title, detail, year, imageUrl, imageAlt, ...props }) => {
	return (
		<TimelineItem {...props}>
			<Grid>
				<Left>
					<Counter>{counter}</Counter>
				</Left>

				<Right>
					<Thumbnail>
						<Image src={imageUrl} alt={imageAlt} />
					</Thumbnail>
					<Info>
						<Year>{year}</Year>
						<Header>{title}</Header>
						<Detail>{detail}</Detail>
					</Info>
				</Right>
			</Grid>
		</TimelineItem>
	)
}

export default styled(Wrapper)``
