import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import * as Color from "../../utils/color"
import { desktop } from "../../utils/breakpoint"

const Detail = styled.p`
	${tw`text-sm md:text-base xl:text-lg`}
	color: ${Color.primary_800};
`

const Header = styled.h2`
	${tw`text-2xl md:text-5xl xl:text-6xl leading-tight`}
	font-weight: bold;

	color: ${Color.primary};
`

const Icon = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: ${Color.primary_200};

	display: flex;
	justify-content: center;
	align-items: center;

	> svg {
		fill: ${Color.primary};
	}
`

const Right = styled.div`
	text-align: center;
`

const Left = styled.div`
	${tw`mb-4`}

	display: flex;
	justify-content: center;
`

const Grid = styled.div`
	display: grid;

	@media ${desktop} {
		grid-template-columns: min-content auto;

		${Right} {
			${tw`ml-6`}
			text-align: left;
		}
	}
`

const Card = styled.div`
	${tw`p-8`}
	background-color: ${Color.primary_100};
	border-radius: 8px;
`

const IntroCard = ({ title, detail, icon, ...props }) => {
	return (
		<Card {...props}>
			<Grid>
				<Left>
					<Icon>{icon}</Icon>
				</Left>

				<Right>
					<Header>{title}</Header>
					<Detail>{detail}</Detail>
				</Right>
			</Grid>
		</Card>
	)
}

export default styled(IntroCard)``
