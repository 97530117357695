import React from "react"
import styled from "@emotion/styled"

import Inner from "../../../components/Inner"
import IntroCard from "../../../components/SolutionIntro"

import { electronic_iot as data } from "../../../meta/solutions"

const Intro = styled.section``

const Section = () => {
	return (
		<Intro>
			<Inner>
				<IntroCard title={data.title} detail={data.description} icon={data.icon} />
			</Inner>
		</Intro>
	)
}

export default Section
