import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Intro from "../../sections/electronic-and-iot/intro"
import Timeline from "../../sections/electronic-and-iot/timeline"

const Solution = styled.section``

const Content = styled.div`
	padding: 2em 0;
	display: grid;
	grid-row-gap: 4em;
`

const Wrapper = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiElectronicAndIotPage {
				nodes {
					TimelineList {
						title
						detail
						year
						thumbnail {
							publicURL
						}
					}
				}
			}
		}
	`)

	const list = data.allStrapiElectronicAndIotPage.nodes[0].TimelineList

	return (
		<Solution>
			<Content>
				<Intro />
				<Timeline list={list} />
			</Content>
		</Solution>
	)
}

export default Wrapper
